import './App.scss';

function App() {
  return (
    <div className="app">
      <div className="app-header">
        <div className="app-header-wrapper">
          <a href="https://bit.ly/kargo-excellerate" target="_blank" rel="noopener noreferrer">
            <img src={process.env.PUBLIC_URL + '/img/excellerate-poster.jpg'} className="app-header-wrapper-poster" alt="Excellerate Poster" />
          </a>
        </div>
      </div>
      <div className="app-footer">
        Click the poster to access the <a href="https://bit.ly/kargo-excellerate" target="_blank" rel="noopener noreferrer">Registration Form</a>
      </div>
    </div>
  );
}

export default App;
